exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-biodust-js": () => import("./../../../src/pages/biodust.js" /* webpackChunkName: "component---src-pages-biodust-js" */),
  "component---src-pages-certificacion-js": () => import("./../../../src/pages/certificacion.js" /* webpackChunkName: "component---src-pages-certificacion-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-impermeabilizacion-js": () => import("./../../../src/pages/impermeabilizacion.js" /* webpackChunkName: "component---src-pages-impermeabilizacion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-protectseal-js": () => import("./../../../src/pages/protectseal.js" /* webpackChunkName: "component---src-pages-protectseal-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-reforzamiento-js": () => import("./../../../src/pages/reforzamiento.js" /* webpackChunkName: "component---src-pages-reforzamiento-js" */),
  "component---src-pages-tratamientopisos-js": () => import("./../../../src/pages/tratamientopisos.js" /* webpackChunkName: "component---src-pages-tratamientopisos-js" */)
}

